<template>

<app-content :loading="is.loading || is.checking" :fullheight="true">

	<app-content-head title="Checkout" :is-app="true" />

	<app-content-body :no-padding="true" class="loan">

		<com-game :game="loan.game" :waiting="is.waiting" />
		<com-clock :loan="loan" v-if="!isNew" />
		<com-waiting :waiting="is.waiting" v-if="is.waiting" />
		<com-badge :badge="loan.badge" :locked="isLocked" :ignoreLimit="ignore" v-on:change="onBadgeChange" />
		<com-players :players="loan.players" :locked="is.checkedin" :ignoreLimit="ignore" v-on:change="onPlayersChange" />

		<com-report v-if="!isNew && report" v-on:change="onReportTextChange" />

		<div class="loan-options">

			<app-panel-check v-if="!isNew" text="Report issue with game" class="loan-options-item" v-on:toggle="onReportChange" :active="report" />
			<app-panel-check text="Log as anonymous play" class="loan-options-item" v-on:toggle="onLogChange" :active="model.log" />
			<app-panel-check v-if="isNew" text="Ignore checkout limit" class="loan-options-item" v-on:toggle="ignore = !ignore" :active="ignore" />

		</div> 

		<div class="loan-actions">

			<app-button v-if="!isNew && model.status === this.$constants.checkout.loan.status.active" :loading="is.checkingin" text="Checkin" theme="blue" v-on:click="onCheckinClick" />
			<app-button text="Close" theme="grey" v-on:click="onCloseClick" />

		</div>

	</app-content-body>

</app-content>

</template>

<script>

import Vue from 'vue'

export default {

	components: {
		'com-game': () => import('./common/Game'),
		'com-badge': () => import('./checkoutloan/Badge'),
		'com-clock': () => import('./checkoutloan/Clock'),
		'com-waiting': () => import('./checkoutloan/Waiting'),
		'com-report': () => import('./checkoutloan/Report'),
		'com-players': () => import('./checkoutloan/Players')
	},
 
	data: function() {
	
		return {
			is: {
				loading: true,
				saving: false,
				checkingin: false,
				checkedin: false,
				checking: false,
				waiting: 0
			},
			actions: {
				created: 'Checkout successful.',
				updated: 'Checkout updated.',
				deleted: 'Checkout cancelled.'
			},
			errors: {
				limit: 'Too many active loans',
				notfound: 'Cannot identify badge'
			},
			ignore: false,
			report: false,
			loan: false,
			model: false
		}

	},

	created: function() {

		this.$pubsub.$on('scan', this.onScanDetected)

		this.load()

	},

	beforeDestroy: function() {

		this.$pubsub.$off('scan', this.onScanDetected)

	},

	computed: {

		isNew: function() {

			return this.$route.params.id === 'new'

		},

		isLocked: function() {

			return this.$route.params.id !== 'new'

		}

	},

	methods: {

		onCloseClick: function() {

			this.$router.push({
				name: 'Checkout.Dashboard'
			})

		},

		onScanDetected: function(code) {

			this.is.checking = true

			this.$api.get(['checkout/badge', code], {
				ignoreLimit: (this.ignore) ? 1 : 0
			}).then(function(json) {
				
				if (this.model.badge) {

					var players = this.$_.pluck(this.loan.players, 'id')
					players.push(json.badge.id)

					this.onPlayersChange({
						value: players,
						player: json.badge
					})

				} else {

					this.onBadgeChange({
						value: json.badge.id,
						badge: json.badge
					})

				}

				this.is.checking = false

			}.bind(this), function(json) {

				this.is.checking = false

				this.$notify({
					message: this.errors[json.reason],
					type: 'error'
				})

			}.bind(this))

		},

		load: function() {

			this.$api.get(['checkout/loan', this.$route.params.id], this.$route.query).then(function(json) {

				Vue.set(this, 'loan', json.loan)
				Vue.set(this, 'model', json.model)

				this.is.loading = false

				if (this.model.id && this.$route.query.scanned && this.model.status === this.$constants.checkout.loan.status.active) this.onCheckinClick()

			}.bind(this))

		},

		save: function() {

			this.is.saving = true

			this.$api.post(['checkout/loan', this.$route.params.id], this.model).then(function(json) {

				Vue.set(this, 'loan', json.loan)
				Vue.set(this, 'model', json.model)

				this.is.saving = false

				if(json.action) this.$notify({
					message: [this.actions[json.action]]
				})

			}.bind(this))

		},

		onCheckinClick: function() {

			this.is.checkingin = true

			this.$api.patch(['checkout/loan', this.$route.params.id]).then(function(json) {

				Vue.set(this, 'loan', json.loan)
				Vue.set(this, 'model', json.model)
				
				this.$notify({
					message: 'Checkin completed'
				})
				
				this.is.waiting = json.waiting
				this.is.checkedin = true
				this.is.checkingin = false

			}.bind(this))

		},

		onLogChange: function() {

			this.model.log = (this.model.log) ? 0 : 1

			this.save()

		},

		onReportChange: function() {

			this.report = !this.report

			if (!this.report) this.model.report = ''

			this.save()

		},

		onReportTextChange: function(text) {

			this.model.report = text

			this.save()

		},

		onBadgeChange: function(e) {

			this.model.badge = e.value
			this.loan.badge = e.badge

			this.save()

		},

		onPlayersChange: function(e) {

			this.model.players = e.value

			if (e.player) {

				this.loan.players.push(e.player)

			} else {

				this.loan.players = this.$_.filter(this.loan.players, function(player) {

					return this.$_.contains(this.model.players, player.id)

				}.bind(this))

			}

			this.save()

		}

	}

}

</script>

<style scoped>

.loan {
	background-color: #fff;
	display: flex;
	flex-direction: column;
}

.loan-checkin {
	padding: 10px;
}

.loan-checkin >>> .button {
	width: 100%;
}

.loan-options-item {
	padding: 0px 10px;
}

.loan-options-item.check:first-child {
	margin-top: 0px;
}

.is-desktop .loan-options-item:hover {
    background-color: rgba(0, 0, 0, 0.15);
	margin: 0px 0px 4px 0px;
	border-radius: 0px;
}

.loan-options-item >>> .check-text {
	font-size: 16px;
	font-weight: 300;
	color: #333;
}

.loan-options-item >>> .check-box {
	background-color: #e7e7e7;
    font-size: 16px;
    color: #ffffff;
}

.loan-options-item:hover {
	background-color: #fff;
	padding: 0px 10px;
	margin: 0px 0px 4px 0px;
}

.loan-options-item:hover >>> .check-box {
	background-color: #ccc!important;
	color: #fff!important;
}

.loan-options-item >>> .check-box.is-active,
.loan-options-item:hover >>> .check-box.is-active {
	background-color: #ffbe7a!important;
    color: #fff!important;
}

.loan-actions {
	flex-shrink: 0;
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	background-color: #384352;
}

.loan-actions >>> .button {
	width: calc(50% - 10px);
}

</style>
